/**
 * Set the testadv parameter based on the current URL parameters.
 *
 * @param {string} adUrl The ad url.
 * @return {string}
 */
export default function adUrlTestAd(adUrl) {
    var URLParams = new URLSearchParams(window.location.search);
    // The `testadv` parameter.
    return URLParams.has('testadv')
        ? adUrl.replace('[testadv]', encodeURIComponent(URLParams.get('testadv')))
        : adUrl.replace('[testadv]', '');
}
