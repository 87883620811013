/**
 * Set the media duration.
 *
 * @param {string} adUrl     The ad url.
 * @param {object} mediaItem The media object.
 * @return {string}
 */
export default function adUrlMediaTags(adUrl, mediaItem) {
    var mediaTags = '';
    if (typeof mediaItem.tags === 'string' && mediaItem.tags.length) {
        // Remove the playlist exclusion tag.
        mediaTags = mediaItem.tags.replace(/(wp-playlist-exclude:\s[true|false]+[,]?)/gi, '');
        // Apply filtering for any remaining tags.
        if (mediaTags.length) {
            // Remove any post tag or primary tag media tags.
            mediaTags = mediaTags.replace(/(wp-[^-]+-[^:]+:\s)/gi, '');
            // Convert the string to an object/array and make the array unique.
            var mediaTagsObject = mediaTags
                .split(',')
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            // Convert object to comma delimited string.
            if (typeof mediaTagsObject === 'object') {
                mediaTags = mediaTagsObject.toString();
            }
        }
    }
    // The `content` parameter.
    return adUrl.replace('[mediaInfoTags]', encodeURIComponent(mediaTags));
}
