/**
 * Reassign the player name in the ad url.
 *
 * @param {string} adUrl The ad url.
 * @param {object} config The player config.
 */
export default function adUrlPlayerName(adUrl, config) {
    // The `player_name` parameter.
    return typeof (config === null || config === void 0 ? void 0 : config.pid) !== 'undefined'
        ? adUrl.replace('[playerName]', encodeURIComponent(config.pid))
        : adUrl.replace('[playerName]', '');
}
