var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * A promise to resolve the `getVideoServerUrl` method.
 *
 * @param item The media item object.
 */
export default function videoServerUrl(item) {
    return new Promise(function (resolve, reject) {
        window.pbjs.getVideoServerUrl(resolve);
        setTimeout(reject, 3000); // 3s timeout in case getVideoServerUrl doesn't load.
    }).then(function (tag) {
        var adSchedule = {
            adschedule: [{
                    tag: tag,
                    offset: 'pre',
                }],
        };
        return __assign(__assign({}, item), adSchedule);
    }).catch(function () { return item; });
}
