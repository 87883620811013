import adUrlPermutive from '@/platform/jw-player/client/js/advertise/adUrlPermutive';
import adUrlPermalink from '@/platform/jw-player/client/js/advertise/adUrlPermalink';
import adUrlPlayerName from '@/platform/jw-player/client/js/advertise/adUrlPlayerName';
import adUrlTestAd from '@/platform/jw-player/client/js/advertise/adUrlTestAd';
/**
 * The static method here is to perform a string replacement on the original ad url
 * that pertain to parameters that won't change. In this case this relates to the
 * player, page definitions, and any third party data.
 *
 * @param {string} adUrl        The ad url.
 * @param {object} pluginConfig The config options passed to the original player plugin.
 * @param {object} playerConfig The config options set in the player from the JW dashboard settings.
 * @return {string}
 */
export default function getStaticAdUrl(adUrl, pluginConfig, playerConfig) {
    var baseAdUrl = '';
    // Check that adUrl is not `undefined` and not `null`.
    if (typeof adUrl !== 'undefined' && adUrl) {
        baseAdUrl = adUrl;
        // Third party Permutive data.
        baseAdUrl = adUrlPermutive(baseAdUrl);
        // Assign the permalink to various parameters in the ad url.
        baseAdUrl = adUrlPermalink(baseAdUrl, pluginConfig);
        // Player name reassignment.
        baseAdUrl = adUrlPlayerName(baseAdUrl, playerConfig);
        // Test URL signifier.
        baseAdUrl = adUrlTestAd(baseAdUrl);
    }
    return baseAdUrl;
}
