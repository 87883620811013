/**
 * Set the same URL to different URL parameters, for analytics.
 *
 * @param {string} adUrl The ad url.
 * @param {object} config The player config.
 */
export default function adUrlPermalink(adUrl, config) {
    var baseAdUrl = adUrl;
    var permalink = '';
    if (typeof (config === null || config === void 0 ? void 0 : config.url) !== 'undefined') {
        permalink = encodeURIComponent(config === null || config === void 0 ? void 0 : config.url);
    }
    // The `mediaURL` parameter.
    baseAdUrl = baseAdUrl.replace('[mediaURL]', permalink);
    // The `url` parameter.
    baseAdUrl = baseAdUrl.replace('[windowLocationHref]', permalink);
    // The `description_url` parameter.
    baseAdUrl = baseAdUrl.replace('[descURL]', permalink);
    return baseAdUrl;
}
