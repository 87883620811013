/**
 * Logic to bootstrap the `pbjs` window object.
 */
export default function jwPrebid() {
    window.pbjs = window.pbjs || {};
    // Create the `que` property.
    window.pbjs.que = window.pbjs.que || [];
}
/**
 * A promise for when prebid object is set in window.
 */
export function pbjsLoaded() {
    return new Promise(function (resolve) {
        window.pbjs.que.push(resolve);
        setTimeout(resolve, 3000); // 3s timeout in case Prebid.js doesn't load.
    });
}
