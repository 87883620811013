/**
 * Set the media title.
 *
 * @param {string} adUrl     The ad url.
 * @param {object} mediaItem The media object.
 * @return {string}
 */
export default function adUrlMediaTitle(adUrl, mediaItem) {
    // The `mediaTitle` parameter.
    return (mediaItem === null || mediaItem === void 0 ? void 0 : mediaItem.title)
        ? adUrl.replace('[mediaInfoName]', encodeURIComponent(mediaItem.title))
        : adUrl.replace('[mediaInfoName]', '');
}
