/**
 * Set the media ID.
 *
 * @param {string} adUrl     The ad url.
 * @param {object} mediaItem The media object.
 * @return {string}
 */
export default function adUrlMediaId(adUrl, mediaItem) {
    // The `mediaId` parameter.
    return mediaItem.mediaid
        ? adUrl.replace('[mediaInfoId]', encodeURIComponent(mediaItem.mediaid))
        : adUrl.replace('[mediaInfoId]', '');
}
