/**
 * Perform string replace to properly set the Permutive Segment.
 * Permutive segments are stored in localStorage.
 *
 * @param {string} adUrl The Ad URL.
 * @return {string} The Ad URL with the permutive segment assigned the value in LocalStorage.
 */
export default function adUrlPermutive(adUrl) {
    // eslint-disable-next-line no-underscore-dangle
    var playerSegsPermData = JSON.parse(localStorage._pdfps || '[]').slice(0, 250).join(',');
    // This is the `custom_params` parameter (ie: cust_params=permutive=[permutiveSegments]).
    return adUrl.replace('[permutiveSegments]', encodeURIComponent(playerSegsPermData));
}
