import adUrlMediaId from '@/platform/jw-player/client/js/advertise/adUrlMediaId';
import adUrlMediaTitle from '@/platform/jw-player/client/js/advertise/adUrlMediaTitle';
import adUrlMediaDescription from '@/platform/jw-player/client/js/advertise/adUrlMediaDescription';
import adUrlMediaDuration from '@/platform/jw-player/client/js/advertise/adUrlMediaDuration';
import adUrlMediaTags from '@/platform/jw-player/client/js/advertise/adUrlMediaTags';
import adUrlTimestamp from '@/platform/jw-player/client/js/advertise/adUrlTimestamp';
/**
 * The dynamic logic here is to perform string replacements in the ad url that
 * relate to the media that is playing in the player. Since a playlist loads
 * different videos, we want the ad url to reflect the new video.
 *
 * @param {string} adUrl     The ad url.
 * @param {object} mediaItem The media item to be evaluated.
 * @return {string}
 */
export default function getDynamicAdUrl(adUrl, mediaItem) {
    var mediaAdUrl = '';
    // Check that adUrl is not `undefined` and not `null`.
    if (typeof adUrl !== 'undefined' && adUrl) {
        mediaAdUrl = adUrl;
        // The Media ID.
        mediaAdUrl = adUrlMediaId(mediaAdUrl, mediaItem);
        // The Media Title.
        mediaAdUrl = adUrlMediaTitle(mediaAdUrl, mediaItem);
        // The Media Description.
        mediaAdUrl = adUrlMediaDescription(mediaAdUrl, mediaItem);
        // The Media Length, in seconds.
        mediaAdUrl = adUrlMediaDuration(mediaAdUrl, mediaItem);
        // The Media Tags.
        mediaAdUrl = adUrlMediaTags(mediaAdUrl, mediaItem);
        // The Media Timestamp of when it loaded in the player.
        mediaAdUrl = adUrlTimestamp(mediaAdUrl);
    }
    return mediaAdUrl;
}
