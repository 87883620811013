/**
 * Set the media description.
 *
 * @param {string} adUrl     The ad url.
 * @param {object} mediaItem The media object.
 * @return {string}
 */
export default function adUrlMediaDescription(adUrl, mediaItem) {
    // The `mediaDesc` property.
    return (mediaItem === null || mediaItem === void 0 ? void 0 : mediaItem.description)
        ? adUrl.replace('[mediaInfoDescription]', encodeURIComponent(mediaItem.description))
        : adUrl.replace('[mediaInfoDescription]', '');
}
