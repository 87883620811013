/**
 * Assign the timestamp for when the video begins.
 *
 * @param {string} adUrl The ad url.
 * @return {string}
 */
export default function adUrlTimestamp(adUrl) {
    // The `correlator` parameter.
    return adUrl.replace('[timestamp]', encodeURIComponent(new Date().getTime()));
}
