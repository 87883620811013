/**
 * Set the media duration.
 *
 * @param {string} adUrl     The ad url.
 * @param {object} mediaItem The media object.
 * @return {string}
 */
export default function adUrlMediaDuration(adUrl, mediaItem) {
    // The `length` parameter in seconds.
    return (mediaItem === null || mediaItem === void 0 ? void 0 : mediaItem.duration)
        ? adUrl.replace('[playerDuration]', encodeURIComponent(mediaItem.duration))
        : adUrl.replace('[playerDuration]', '');
}
