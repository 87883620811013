/**
 * Get the original Ad URL from the Ad Schedule set in JW Player Dashboard.
 *
 * @param playerConfig
 * @return {string}
 */
export default function getAdSchedule(playerConfig) {
    var schedule = playerConfig.advertising.schedule;
    var adUrl = '';
    if (typeof schedule === 'string') {
        adUrl = schedule;
    }
    else if (Array.isArray(schedule)
        && typeof schedule[0].tag !== 'undefined') {
        adUrl = schedule[0].tag;
    }
    return adUrl;
}
